import {inject, Injectable} from '@angular/core';
import {CanActivateFn} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class StudentGuard  {
  constructor() {}

  canActivate(): boolean {
    return true;
  }
}

export const studentGuardFn: CanActivateFn = (route, state) => {
  return inject(StudentGuard).canActivate();
}

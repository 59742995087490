import {AdminTabSearchState, IAdminState} from "./admin.state";
import {Action, createReducer, on} from "@ngrx/store";
import {
  setAdminOrganizationDashboardStateAction,
  setAdminOrganizationEventsStateAction,
  setAdminPlacementDashboardStateAction,
  setAdminPlacementEventsStateAction,
  setAdminSupervisorDashboardStateAction,
  setAdminSupervisorEventsStateAction,
  setAdminUserDashboardStateAction,
  setAdminUserVolunteerDashboardStateAction
} from "./admin.actions";
import {TableData} from "../../../shared/model/tableData.model";
import {ITableOptions} from "../../../shared/model/table-options.model";

const initialTableOptions = <ITableOptions> {
  currentPage: 1,
  pageSize: 25,
  sortColumn: undefined
};

export const initialAdminState: IAdminState = {
  organizationTab: <AdminTabSearchState> {
    textFilter: "",
    showInactive: false,
    results: <TableData[]> [],
    tableOptions: initialTableOptions
  },

  organizationEventsTab: <AdminTabSearchState> {
    textFilter: "",
    showInactive: false,
    results: <TableData[]> [],
    tableOptions: initialTableOptions
  },

  supervisorTab: <AdminTabSearchState> {
    textFilter: "",
    showInactive: false,
    results: <TableData[]> [],
    tableOptions: initialTableOptions
  },

  supervisorEventsTab: <AdminTabSearchState> {
    textFilter: "",
    showInactive: false,
    results: <TableData[]> [],
    tableOptions: initialTableOptions
  },

  placementTab: <AdminTabSearchState> {
    textFilter: "",
    showInactive: false,
    results: <TableData[]> [],
    tableOptions: initialTableOptions
  },

  placementEventsTab: <AdminTabSearchState> {
    textFilter: "",
    showInactive: false,
    results: <TableData[]> [],
    tableOptions: initialTableOptions
  },

  userTab: <AdminTabSearchState> {
    textFilter: "",
    showInactive: false,
    results: <TableData[]> [],
    tableOptions: initialTableOptions
  },

  userVolunteerTab: <AdminTabSearchState> {
    textFilter: "",
    showInactive: false,
    results: <TableData[]> [],
    tableOptions: initialTableOptions
  },
};

const adminReducer = createReducer(
  initialAdminState,

  on(setAdminOrganizationDashboardStateAction,
    (state, action): IAdminState => ({
      ...state,
      organizationTab: action.newState
    })
  ),

  on(setAdminSupervisorDashboardStateAction,
    (state, action): IAdminState => ({
      ...state,
      supervisorTab: action.newState
    })
  ),

  on(setAdminPlacementDashboardStateAction,
    (state, action): IAdminState => ({
      ...state,
      placementTab: action.newState
    })
  ),

  on(setAdminUserDashboardStateAction,
    (state, action): IAdminState => ({
      ...state,
      userTab: action.newState
    })
  ),

  on(setAdminUserVolunteerDashboardStateAction,
    (state, action): IAdminState => ({
      ...state,
      userVolunteerTab: action.newState
    })
  ),

  on(setAdminOrganizationEventsStateAction,
    (state, action): IAdminState => ({
      ...state,
      organizationEventsTab: action.newState
    })
  ),

  on(setAdminSupervisorEventsStateAction,
    (state, action): IAdminState => ({
      ...state,
      supervisorEventsTab: action.newState
    })
  ),

  on(setAdminPlacementEventsStateAction,
    (state, action): IAdminState => ({
      ...state,
      placementEventsTab: action.newState
    })
  ),
);

export function adminReducers(state: IAdminState, action: Action) {
  return adminReducer(state, action);
}

import {Component} from '@angular/core';
import {AuthService} from '../../core/service/auth.service';
import {Observable} from "rxjs";

@Component({
  selector: 'app-admin-landing-page',
  templateUrl: './admin-landing-page.component.html',
  styleUrls: ['./admin-landing-page.component.scss']
})
export class AdminLandingPageComponent {
  public isImpersonating$!: Observable<boolean | undefined>;
  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.isImpersonating$ = this.authService.isImpersonating();
  }
}

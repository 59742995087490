import {Action, createReducer, on} from '@ngrx/store';
import {stateHelperFunctions} from '../stateHelperFunctions';
import {
    getSupervisorAction,
    getSupervisorFailureAction,
    getSupervisorSuccessAction
} from './supervisor.actions';
import {ISupervisorState} from './supervisor.state';

export const initialSupervisorState: ISupervisorState = {
    supervisors: [],
    loadingSupervisors: [],
    error: undefined
}

const supervisorReducer = createReducer(
    initialSupervisorState,

    on(getSupervisorAction, (state, action): ISupervisorState => {
        return stateHelperFunctions.hasItem(state, action.id) ? state : ({
            ...state,
            loadingSupervisors: state.loadingSupervisors.concat([action.id])
        })}
    ),

    on(getSupervisorSuccessAction, (state, action): ISupervisorState => ({
        ...state,
        loadingSupervisors: stateHelperFunctions.removeLoadingId(state.loadingSupervisors, action.data.id) ,
        supervisors: state.supervisors?.concat(action.data)
        })
    ),

    on(getSupervisorFailureAction, (state, action): ISupervisorState => ({
        ...state,
        loadingSupervisors: stateHelperFunctions.removeLoadingId(state.loadingSupervisors, action.id) ,
        error: action.error.error
        })
    ),
);

export function supervisorReducers(state: ISupervisorState, action: Action) {
    return supervisorReducer(state, action);
}

import {inject, Injectable} from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree
} from "@angular/router";
import {UserInfoService} from "../../core/service/user-info.service";
import {AuthService} from "../../core/service/auth.service";
import {IUserInfoComplete} from "../../shared/model/userInfo.model";
import {Roles} from "../../shared/constants/roles.constants";
import {filter, map, Observable} from "rxjs";
import {take} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AccessRevokedGuard implements CanActivate {
  constructor(private router: Router, private userInfo: UserInfoService, private auth: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {

    return this.auth.getCurrentUserInfo().pipe(
      filter((userInfo: IUserInfoComplete | undefined) => !!userInfo),
      take(1),
      map((userInfo: IUserInfoComplete) => {
        const hasRevokedAccess = userInfo.userRoles.includes(Roles.ACCESS_REVOKED);

        if (state.url === '/access-revoked') {
          return hasRevokedAccess ? true : this.router.createUrlTree(['/']);
        }

        if (hasRevokedAccess) {
          return this.router.createUrlTree(['/access-revoked']);
        }

        return true;
      })
    );
  }
}

export const accessRevokedGuardFn: CanActivateFn = (route, state) => {
  return inject(AccessRevokedGuard).canActivate(route, state);
}

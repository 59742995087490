import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from "../core/service/auth.service";
import {Observable, Subject, takeUntil} from "rxjs";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit, OnDestroy {
  public isImpersonating$!: Observable<boolean | undefined>;
  public isLoading$!: Observable<boolean | undefined>;
  public loadingImagePath: string;

  private endSubscriptions$ = new Subject();

  constructor(private authService: AuthService) {
    if (environment.production) {
      this.loadingImagePath = "assets/images/Story.jpg"
    } else {
      this.loadingImagePath = "assets/images/grumpycat.jpg";
    }
  }

  ngOnInit(): void {
    this.isImpersonating$ = this.authService.isImpersonating().pipe(takeUntil(this.endSubscriptions$));
    this.isLoading$ = this.authService.isLoading().pipe(
      takeUntil(this.endSubscriptions$),
      );
  }

  ngOnDestroy() {
    this.endSubscriptions$.next(true);
    this.endSubscriptions$.complete();
  }
}





import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {getUserInfoAction} from './core/store/auth/auth.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public title = 'serve-ui';

  constructor(private store: Store) { }


  ngOnInit() {
    this.store.dispatch(getUserInfoAction());
  }
}

import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {exhaustMap, of} from 'rxjs';
import {Router} from "@angular/router";
import {skippingStudentDashboardAction, skippingStudentDashboardSuccessAction} from "../navigation.actions";

@Injectable()
export class SkipStudentDashboardEffect {

  constructor(private actions$: Actions,
    private router: Router) {
  }

  // noinspection TypeScriptValidateTypes
  skipDashboard$ = createEffect(() => this.actions$.pipe(
    ofType(skippingStudentDashboardAction),
    exhaustMap(() => {
      if (!this.wasOnAnotherPage() && this.isOnStudentDashboard()) {
        this.router.navigateByUrl('/search-opportunity');
      }
      return of(skippingStudentDashboardSuccessAction());
    })
  ));

  private isOnStudentDashboard(): boolean {
    return this.router.url == '/' || this.router.url == '/student-dashboard';
  }

  private wasOnAnotherPage(): boolean {
    return !!this.router.lastSuccessfulNavigation?.previousNavigation;
  }
}

import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { RegisterRepository } from "../../../repository/register.repository";
import { attemptCSERRegisterAction, attemptCSERRegisterFailureAction, attemptCSERRegisterSuccessAction } from "../register.actions";
import { catchError, exhaustMap, map, mergeMap, of, tap } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";

@Injectable()
export class AttemptCSERRegistrationEffect {
    constructor(private actions$: Actions,
        private store: Store,
        private registerRepo: RegisterRepository,
    ) { }

    // noinspection TypeScriptValidateTypes
    registerCSER$ = createEffect(() => this.actions$.pipe(
        ofType(attemptCSERRegisterAction),
        mergeMap(action => {
            return this.registerRepo.registerCSERCourseInBanner(action.serviceRegistration).pipe(
                map(response =>
                    attemptCSERRegisterSuccessAction({ registrationMessage: response, registration: action.serviceRegistration })),
                catchError((errorResponse: HttpErrorResponse) => of(attemptCSERRegisterFailureAction({ error: errorResponse })))
            );
        })
    ));
}
import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {catchError, delay, exhaustMap, map, of, tap, withLatestFrom} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {AuthRepository} from '../../../repository/auth.repository';
import {
  endImpersonateUserAction,
  endImpersonateUserFailureAction,
  endImpersonateUserSuccessAction,
  impersonateUserAction,
  impersonateUserFailureAction,
  impersonateUserSuccessAction,
  logoutAction,
  logoutEndImpersonateUserAction,
  reloadAction,
  switchUserAction,
  switchUserFailureAction,
  switchUserSuccessAction,
} from '../auth.actions';
import { getImpersonateDestinationSelector, isLogoutSelector } from '../auth.selectors';
import {Router} from "@angular/router";


@Injectable()
export class ImpersonateEffect {

    // noinspection TypeScriptValidateTypes
    impersonateUser$ = createEffect(() => this.actions$.pipe(
        ofType(impersonateUserAction, switchUserSuccessAction),
        exhaustMap(({email}) => {
            return this.authRepo.impersonateUser(email).pipe(
                map(response => impersonateUserSuccessAction({data: response})),
                catchError((errorResponse: HttpErrorResponse) => of(impersonateUserFailureAction({error: errorResponse})))
            );
        })
    ));

    // noinspection TypeScriptValidateTypes
    endImpersonateUser$ = createEffect(() => this.actions$.pipe(
        ofType(endImpersonateUserAction, logoutEndImpersonateUserAction),
        exhaustMap(() => {
            return this.authRepo.stopImpersonating().pipe(
                map(response => endImpersonateUserSuccessAction({data: response})),
                catchError((errorResponse: HttpErrorResponse) => of(endImpersonateUserFailureAction({error: errorResponse})))
            );
        })
    ));

    // noinspection TypeScriptValidateTypes
    switchUser$ = createEffect(() => this.actions$.pipe(
        ofType(switchUserAction),
        exhaustMap(({email}) => {
            return this.authRepo.stopImpersonating().pipe(
                map(response => switchUserSuccessAction({data: response,email: email})),
                catchError((errorResponse: HttpErrorResponse) => of(switchUserFailureAction({error: errorResponse})))
            );
        })
    ));

    // noinspection TypeScriptValidateTypes
    redirectAfterImpersonate$ = createEffect(() => this.actions$.pipe(
        ofType(
            impersonateUserSuccessAction,
            endImpersonateUserSuccessAction
        ),
      withLatestFrom(this.store.pipe(select(isLogoutSelector))),
      exhaustMap(([response, isLogout]) => {
        if(isLogout) {
          return of(logoutAction());
        } else {
          return of(reloadAction())
        }
      })
    ));

    // noinspection TypeScriptValidateTypes
    reloadAfterImpersonate = createEffect(() => this.actions$.pipe(
      ofType(reloadAction),
      withLatestFrom(this.store.select(getImpersonateDestinationSelector)),
      tap(([action, destination]) => {
        if (!!destination) {
          this.router.navigateByUrl(destination).then(() => window.location.reload());
        } else {
          window.location.reload();
        }
      }),
    ), { dispatch: false });

    // noinspection TypeScriptValidateTypes
    logout$ = createEffect(() => this.actions$.pipe(
      ofType(logoutAction),
      delay(750),
      tap(() => {
          window.location.replace(`${environment.apiUrl}/logout`);
      })
    ), { dispatch: false });


    constructor(private actions$: Actions,
                private authRepo: AuthRepository,
                private router: Router,
                private store: Store) {
    }
}

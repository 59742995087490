import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {AuditRepository} from "../../../repository/audit.repository";
import {generateAuditAction, getAuditAction, getAuditFailureAction, getAuditSuccessAction} from "../audit.actions";
import {catchError, exhaustMap, map, of} from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";

@Injectable()
export class AuditEffect {

  constructor(private actions$: Actions,
              private store: Store,
              private auditRepo: AuditRepository) {
  }

  // noinspection TypeScriptValidateTypes
  getAudit$ = createEffect(() => this.actions$.pipe(
    ofType(getAuditAction),
    exhaustMap(action => {
      return this.auditRepo.getAuditSnapshot(action.userId).pipe(
        map(response => {
          return !!response ? getAuditSuccessAction({result: response}) : generateAuditAction();
        }),
        catchError((errorResponse: HttpErrorResponse) => of(getAuditFailureAction({error: errorResponse})))
      )
    })
  ));

  // noinspection TypeScriptValidateTypes
  generateAudit$ = createEffect(() => this.actions$.pipe(
    ofType(generateAuditAction),
    exhaustMap(action => {
      return this.auditRepo.generateAudit().pipe(
        map(response => getAuditSuccessAction({result: response})),
        catchError((errorResponse: HttpErrorResponse) => of(getAuditFailureAction({error: errorResponse})))
      )
    })
  ));
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, of, Subject, switchMap, takeUntil} from 'rxjs';
import {OrganizationService} from '../../core/service/organization.service';
import {SupervisorService} from '../../core/service/supervisor.service';
import {IOpportunity, OpportunityStatus} from '../../shared/model/opportunity.model';
import {IOrganization, OrganizationStatus} from '../../shared/model/organization.model';
import {ISupervisor} from '../../shared/model/supervisor.model';
import {UserService} from '../../core/service/user.service';
import {AuthService} from "../../core/service/auth.service";

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit, OnDestroy {
  private endSubscriptions$ = new Subject();
  public userName!: string | undefined;
  public userEmail!: string | undefined;

  //isAdmin shows as false unless the logged in user is an admin
  public isAdmin$!: Observable<boolean | undefined>;
  public isImpersonating$!: Observable<boolean | undefined>;

  constructor(
    private authService: AuthService,
    private supervisorService: SupervisorService,
    private organizationService: OrganizationService,
    private userService: UserService) {
  }

  public opportunities$!: Observable<IOpportunity[]>;
  public organizations: IOrganization[] = [];

  public isLoadingData$!: Observable<boolean>;
  public isContractSigned$!: Observable<boolean>;

  readonly INACTIVE: OrganizationStatus = OrganizationStatus.INACTIVE;

  ngOnInit() {
    //isAdmin is only true if the user logged into the app is an admin, shows as false when impersonating
    this.isAdmin$ = this.authService.isAdmin();
    this.isImpersonating$ = this.authService.isImpersonating();
    this.isContractSigned$ = this.supervisorService.getSupervisorContractSigned();

    this.supervisorService.getCurrentSupervisor().pipe(
      takeUntil(this.endSubscriptions$),
      switchMap((supervisor: ISupervisor) => {
        this.userName = `${supervisor.firstName} ${supervisor.lastName}`;
        this.userEmail = supervisor.email;

        return this.organizationService.getOrganizationsFromLeadId(supervisor.id);
      })).subscribe((organizations: IOrganization[]) => {
        this.organizations = organizations.filter(organization => organization.status === OrganizationStatus.ACTIVE ||
          organization.status === OrganizationStatus.INACTIVE
          || organization.status === OrganizationStatus.PENDING_ADMIN_SIGNATURE
          || organization.status === OrganizationStatus.REJECTED).sort((a, b) => a.name.localeCompare(b.name));
      });

    this.opportunities$ = this.supervisorService.getSupervisedOpportunitiesFromCurrentUser().pipe(
      switchMap(data => {
        return of(data
          .filter(opportunity => opportunity.status === OpportunityStatus.ACTIVE));
      })
    );
  }

  public orgStatusDisplay(status: OrganizationStatus): string {
    switch (status) {
      case OrganizationStatus.INACTIVE:
        return 'Inactive';
      case OrganizationStatus.PENDING_ADMIN_SIGNATURE:
        return 'Pending';
      case OrganizationStatus.REJECTED:
        return 'Rejected';
      default:
        return '';
    }
  }

  public getOrgRoute(org: IOrganization) : string[] {
    if (org.status === OrganizationStatus.INACTIVE || org.status === OrganizationStatus.PENDING_ADMIN_SIGNATURE || org.status === OrganizationStatus.REJECTED) {
      return ['/', 'supervisor', 'organization', 'details', org.id];
    } else {
      return ['/', 'supervisor', 'my-organizations', org.id];
    }
  }

  public logOut(): void {
    this.userService.signOut();
  }

  ngOnDestroy() {
    this.endSubscriptions$.next(true);
    this.endSubscriptions$.complete();
  }

  protected readonly OrganizationStatus = OrganizationStatus;
}

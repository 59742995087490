<div class="row-wrapper">
  <div class="upload-block">
  <h1>Supervisor Upload</h1>
  <form (ngSubmit)="uploadFile('supervisor')" enctype="multipart/form-data">
    <input  type="file" (change)="onFileSelected($event)" />
    <button class="custom-button-small-primary-dark-blue" type="submit">Upload</button>
  </form>
  </div>

  <div class="upload-block">
  <h1>Organization Upload</h1>
  <form (ngSubmit)="uploadFile('organization')" enctype="multipart/form-data">
    <input type="file" (change)="onFileSelected($event)" />
    <button class="custom-button-small-primary-dark-blue"  type="submit">Upload</button>
  </form>
    </div>
</div>

<div class="row-wrapper">
  <div class="upload-block">
  <h1>Opportunity Upload</h1>
  <form (ngSubmit)="uploadFile('opportunity')" enctype="multipart/form-data">
    <input type="file" (change)="onFileSelected($event)" />
    <button class="custom-button-small-primary-dark-blue"  type="submit">Upload</button>
  </form>
  </div>

  <div class="upload-block">
    <h1>Service Reg Upload</h1>
    <form (ngSubmit)="uploadFile('service')" enctype="multipart/form-data">
      <input type="file" (change)="onFileSelected($event)" />
      <button class="custom-button-small-primary-dark-blue"  type="submit">Upload</button>
    </form>
  </div>

</div>

 <br>

<div *ngIf="isRunning">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<div id="logs">
  <h1>Log:</h1>
  <ul *ngIf="logMessages">
    <li *ngFor="let log of logMessages" data-cy="log">
      <div class="logColumn">{{log.message}}</div>
    </li>
  </ul>
</div>

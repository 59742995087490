import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { exhaustMap, filter, map, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AnnouncementRepository } from '../../../repository/announcement.repository';
import {
  getOpportunityAnnouncementRecordsAction, getOpportunityAnnouncementRecordsFailureAction,
  getOpportunityAnnouncementRecordsSuccessAction
} from '../opportunity-config.actions';

import {
  getOpportunityAnnouncementRecordsSelector
} from '../opportunity-config.selectors';

@Injectable()
export class GetOpportunityAnnouncementsEffect {
  constructor(private actions$: Actions,
              private store: Store,
              private announcementRepo: AnnouncementRepository
  ) {}

  // noinspection TypeScriptValidateTypes
  getOpportunityAnnouncements$ = createEffect(() => this.actions$.pipe(
    ofType(getOpportunityAnnouncementRecordsAction),
    concatLatestFrom((action) => this.store.pipe(select(getOpportunityAnnouncementRecordsSelector))),
    filter(([, currentData]) => !(currentData.length > 0)),
    exhaustMap(([action,]) => {
      return this.announcementRepo.getOpportunityAnnouncements(action.id).pipe(
        map(response => getOpportunityAnnouncementRecordsSuccessAction({data: response})),
        catchError((errorResponse: HttpErrorResponse) => of(getOpportunityAnnouncementRecordsFailureAction({error: errorResponse})))
      );
    })
  ));
}

import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatLatestFrom} from '@ngrx/operators';
import {Store} from "@ngrx/store";
import {TermEventRepository} from "../../../repository/term-event.repository";
import {
  getTermEventsAction,
  getTermEventsFailureAction,
  getTermEventsSuccessAction,
  postTermEventAction,
  postTermEventFailureAction,
  postTermEventSuccessAction,
  updateTermEventAction,
  updateTermEventFailureAction,
  updateTermEventSuccessAction
} from "../term-event.actions";
import {catchError, filter, map, mergeMap, of} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";
import {getTermEventsSelector} from "../term-event.selectors";

@Injectable()
export class TermEventsEffect {
  constructor(private actions$: Actions,
              private store: Store,
              private repo: TermEventRepository) {
  }

  // noinspection TypeScriptValidateTypes
  getTermEvents$ = createEffect(() => this.actions$.pipe(
    ofType(getTermEventsAction),
    concatLatestFrom((action) => this.store.select(getTermEventsSelector(action.termCode))),
    filter(([action,eventList]) => !eventList.some(event => event.termCode == action.termCode)),
    mergeMap(([action,]) => {
      return this.repo.getTermEvents(action.termCode).pipe(
        map(response => getTermEventsSuccessAction({data: response})),
        catchError((errorResponse: HttpErrorResponse) => of(getTermEventsFailureAction({error: errorResponse})))
      );
    })
  ));

  // noinspection TypeScriptValidateTypes
  postTermEvent$ = createEffect(() => this.actions$.pipe(
    ofType(postTermEventAction),
    mergeMap((action) => {
      return this.repo.postTermEvent(action.data).pipe(
        map(response => postTermEventSuccessAction({data: response})),
        catchError((errorResponse: HttpErrorResponse) => of(postTermEventFailureAction({error: errorResponse})))
      );
    })
  ));

  // noinspection TypeScriptValidateTypes
  updateTermEvent$ = createEffect(() => this.actions$.pipe(
    ofType(updateTermEventAction),
    mergeMap((action) => {
      return this.repo.updateTermEvent(action.data).pipe(
        map(response => updateTermEventSuccessAction({data: response})),
        catchError((errorResponse: HttpErrorResponse) => of(updateTermEventFailureAction({error: errorResponse})))
      );
    })
  ));
}

import {INavigationState} from "./navigation.state";
import {Action, createReducer, on} from "@ngrx/store";
import {
  getNavigationFailureAction,
  getNavigationStateAction,
  skippingStudentDashboardSuccessAction,
  updateNavigationFailureAction,
  updateNavigationStateAction,
} from "./navigation.actions";

export const initialNavigationState: INavigationState = {
  previousUrl: "",
  loading: false,
  error: undefined,
  hasSkippedStudentDashboard: false,
}

const navigationReducer = createReducer(
  initialNavigationState,

  on(getNavigationStateAction,
  (state, action): INavigationState => ({
      ...state,
      loading: false,
      error: undefined
  })),

  on(updateNavigationStateAction,
    (state, action): INavigationState => ({
      ...state,
      previousUrl: action.data,
      loading: false,
      error: undefined
    })),

  on(getNavigationFailureAction,
    updateNavigationFailureAction,
    (state, action): INavigationState => ({
      ...state,
      loading: false,
      error: action.error.error
  })),

  on(skippingStudentDashboardSuccessAction,
    (state, action): INavigationState => ({
      ...state,
      hasSkippedStudentDashboard: true
  })),

);

export function navigationReducers(state: INavigationState, action: Action) {
  return navigationReducer(state, action);
}

import {IAuditState} from "./audit.state";
import {Action, createReducer, on} from "@ngrx/store";
import {generateAuditAction, getAuditAction, getAuditFailureAction, getAuditSuccessAction} from "./audit.actions";

export const initialAuditState: IAuditState = {
  audit: undefined,

  isLoading: false,
  error: undefined,
  isBannerDown: false,
};

const auditReducer = createReducer(
  initialAuditState,

  on(getAuditAction,
    (state): IAuditState => ({
      ...state,
      isLoading: true,
      error: undefined,
      isBannerDown:false
    })
  ),

  on(generateAuditAction,
    (state): IAuditState => ({
      ...state,
      isLoading: true,
      error: undefined,
      isBannerDown:false
    })
  ),

  on(getAuditSuccessAction,
    (state, action): IAuditState => ({
      ...state,
      audit: action.result,
      isLoading: false,
      error: undefined,
      isBannerDown: false
    })
  ),

  on(getAuditFailureAction,
    (state, action): IAuditState => ({
      ...state,
      isLoading: false,
      error: action.error,
      isBannerDown: (action.error.error.error.includes("I/O error on GET request"))
    })
  ),
);

export function auditReducers(state: IAuditState, action: Action) {
  return auditReducer(state, action);
}

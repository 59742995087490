import {Injectable} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {catchError, Observable, of} from "rxjs";
import {ILogMessage} from "../../shared/model/log-message.model";

const BASE_URL: string = `${environment.apiUrl}/admin/uploads`

@Injectable({
  providedIn: 'root'
})

export class LogMessageRepository {
  constructor(private http: HttpClient) {
  }

  public getLogMessage(): Observable< ILogMessage[]> {
    return this.http.get<ILogMessage[]>(`${BASE_URL}/logs`);
  }

  public addLogMessage(logMessage: Partial<ILogMessage>): Observable<string> {
    return this.http.post<string>(`${BASE_URL}`, logMessage).pipe(
      catchError(err => {console.log(err); return of(err)})
    );
  }

}

<div class="title">
  <div class="large-bold-text">Global Settings</div>
</div>
<div class="settings f-row">
  <div class="labels">
    <div class="small-reg-text">Max Volunteers per Opportunity</div>
    <div class="x-small-italic">Updating this will not effect opportunities that will then become over the limit</div>
  </div>
  <input class="custom-input supervisors-input" matInput [placeholder]="updatedMaxVolunteerCount.toString()"
         [disabled]="!isEditingLimit" type="number" inputmode="numeric" [(ngModel)]="updatedMaxVolunteerCount">
  @if (!isEditingLimit) {
    <div class="icon-wrapper" (click)="toggleEditing()">
      <app-icon icon="edit" class="edit-icon large-icon"></app-icon>
    </div>
  } @else {
    <button class="custom-button-large-primary-dark-blue" (click)="save()">Save</button>
    <button class="custom-button-large-secondary" (click)="toggleEditing()">Cancel</button>
  }
</div>




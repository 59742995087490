import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatLatestFrom} from '@ngrx/operators';
import {select, Store} from '@ngrx/store';
import {filter, map, mergeMap, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {SupervisorRepository} from '../../../repository/supervisor.repository';
import {getSupervisorAction, getSupervisorFailureAction, getSupervisorSuccessAction} from '../supervisor.actions';
import {isLoadingDuplicateSupervisorSelector} from '../supervisor.selectors';


@Injectable()
export class GetSupervisorEffect {
    constructor(private actions$: Actions,
                private store: Store,
                private supRepo: SupervisorRepository
    ) {}

    // noinspection TypeScriptValidateTypes
    getSupervisor$ = createEffect(() => this.actions$.pipe(
        ofType(getSupervisorAction),
        concatLatestFrom((action) => this.store.pipe(select(isLoadingDuplicateSupervisorSelector(action.id)))),
        filter(([,isDuplicate]) => !isDuplicate),
        mergeMap(([action,]) => {
            return this.supRepo.getSupervisor(action.id).pipe(
                map(response => getSupervisorSuccessAction({data: response})),
                catchError((errorResponse: HttpErrorResponse) => of(getSupervisorFailureAction({error: errorResponse, id: action.id})))
            );
        })
    ));
}

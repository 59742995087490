import {ISearchMapState} from "./search-map.state";
import {Action, createReducer, on} from "@ngrx/store";
import {
  loadMapDataAction,
  loadMapDataSuccessAction,
  setMapFieldsAction,
  setSearchFiltersAction,
  setSearchMapToggleAction,
  setSearchResultsAction
} from "./search-map.actions";

export const initialSearchOpportunityState: ISearchMapState = {
  currentGeojson: undefined,
  pendingGeojson: undefined,

  isLoading: false,

  offCampus: true,
  termCode: undefined,
  textFilter: '',
  selectedTags: [],

  searchResults: [],
  mapRadius: 0.3,
  mapCenter: undefined,
  mapBounds: undefined,
  mapZoom: 15,

  mapToggle: true,
};

const searchOpportunityReducer = createReducer(
  initialSearchOpportunityState,

  on(loadMapDataAction,
    (state, action): ISearchMapState => ({
      ...state,
      currentGeojson: state.isLoading ? state.currentGeojson : action.geojson,
      pendingGeojson: state.isLoading ? action.geojson : undefined,

      isLoading: true,
    })),

  on(loadMapDataSuccessAction,
    (state): ISearchMapState => ({
      ...state,
      currentGeojson: !!state.pendingGeojson ? state.pendingGeojson : state.currentGeojson,
      pendingGeojson: undefined,

      //if the pending and current geojson is the same, the map won't trigger a change, so we need to exclude that condition
      isLoading: !!state.pendingGeojson && (state.pendingGeojson != state.currentGeojson)
    })),

  on(setMapFieldsAction,
    (state, action): ISearchMapState => ({
      ...state,
      mapBounds: action.mapBounds,
      mapCenter: action.mapCenter,
      mapRadius: action.mapRadius,
      mapZoom: action.mapZoom,
    })),

  on(setSearchResultsAction,
    (state, action): ISearchMapState => ({
      ...state,
      searchResults: action.searchResults,
    })),

  on(setSearchFiltersAction,
    (state, action): ISearchMapState => ({
      ...state,
      offCampus: action.offCampus,
      termCode: action.termCode,
      textFilter: action.textFilter,
      selectedTags: action.selectedTags,
    })),

  on(setSearchMapToggleAction,
    (state, action): ISearchMapState => ({
      ...state,
      mapToggle: action.mapToggle,
    })),
);

export function searchMapReducers(state: ISearchMapState, action: Action) {
  return searchOpportunityReducer(state, action);
}

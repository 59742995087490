import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {Actions} from "@ngrx/effects";

@Injectable()
export class SearchMapEffects {

  // noinspection TypeScriptValidateTypes
// create effect on loadSuccess to dispatch loadAction if there is pending data (check file history)
  constructor(private actions$: Actions,
              private store: Store) {
  }
}

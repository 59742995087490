import {Action, createReducer, on} from "@ngrx/store";
import {IRegisterState} from "./register.state";
import {
  attemptCSERRegisterAction,
  attemptCSERRegisterFailureAction,
  attemptCSERRegisterSuccessAction,
  clearRegistrationDataAction
} from "./register.actions";

export const intialRegisterState: IRegisterState = {
    serviceRegistrationData: undefined,
    registrationResult: undefined,
    error: undefined,
    isRegistering: false
};

const regsiterReducer = createReducer(
    intialRegisterState,

    on(attemptCSERRegisterAction,
        (state, action): IRegisterState => ({
            ...state,
            serviceRegistrationData: action.serviceRegistration,
            registrationResult: undefined,
            error: undefined,
            isRegistering: true
        })
    ),

    on(attemptCSERRegisterSuccessAction,
        (state, action): IRegisterState => ({
            ...state,
            registrationResult: action.registrationMessage,
            isRegistering: false
        })
    ),

    on(attemptCSERRegisterFailureAction,
        (state, action): IRegisterState => ({
            ...state,
            error: action.error.message,
            isRegistering: false
        })
    ),

    on(clearRegistrationDataAction,
        (): IRegisterState => ({
            ...intialRegisterState
        })
    )
);

export function regsiterReducers(state: IRegisterState, action: Action) {
    return regsiterReducer(state, action);
}

import {inject, Injectable} from '@angular/core';
import {CanActivateFn, GuardResult, RedirectCommand, Router} from '@angular/router';
import {AuthService} from "../../core/service/auth.service";
import {filter, Observable, of, switchMap} from "rxjs";
import {take} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AdminGuard  {
  constructor(private router: Router,
              private authService: AuthService) {}

  canActivate(): Observable<GuardResult> {
    return this.authService.getUserId().pipe(
      filter(Boolean),
      take(1),
      switchMap(() => this.authService.isAdmin().pipe(
        switchMap(isAdmin => {
          if (!isAdmin) {
            return of(new RedirectCommand(this.router.parseUrl("/"), {replaceUrl: true}));
          } else {
            return of(isAdmin)
          }
        })
      ))
    );
  }
}

export const adminGuardFn: CanActivateFn = (route, state) => {
  return inject(AdminGuard).canActivate();
}

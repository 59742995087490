import {Injectable} from "@angular/core";
import {LogMessageRepository} from "../repository/logMessage.repository";

@Injectable({ providedIn: 'root' })
export class LogMessageService {

  constructor(private logMessageRepo: LogMessageRepository) {}

  public getLogMessages () {
    return this.logMessageRepo.getLogMessage();
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { exhaustMap, filter, map, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SupervisorRepository } from '../../../repository/supervisor.repository';
import {
  getCurrentSupervisorAction,
  getCurrentSupervisorFailureAction,
  getCurrentSupervisorSuccessAction
} from '../opportunity-config.actions';
import { getCurrentSupervisorSelector } from '../opportunity-config.selectors';


@Injectable()
export class GetCurrentSupervisorEffect {
  constructor(private actions$: Actions,
              private store: Store,
              private supRepo: SupervisorRepository
  ) {}

  // noinspection TypeScriptValidateTypes
  getCurrentSupervisor$ = createEffect(() => this.actions$.pipe(
    ofType(getCurrentSupervisorAction),
    concatLatestFrom((action) => this.store.pipe(select(getCurrentSupervisorSelector))),
    filter(([, currentData]) => !currentData),
    exhaustMap(([action,]) => {
      return this.supRepo.getCurrentSupervisor().pipe(
        map(response => getCurrentSupervisorSuccessAction({data: response})),
        catchError((errorResponse: HttpErrorResponse) => of(getCurrentSupervisorFailureAction({error: errorResponse})))
      );
    })
  ));
}

<header class="f-row align-items-center">
  <app-icon class="logo-icon" icon="logo"></app-icon>
</header>
<main class="f-row access-revoked-container">
  <section class="f-col content">
    <p class="title">Your access to the LU Serve Application is currently restricted.</p>

    <h2>Possible Reasons:</h2>
    <ul>
      <li>Your account is currently blacklisted</li>
      <li>We do not have a record for your email</li>
    </ul>

    <section class="contact-info">
      <h2>Need Assistance?</h2>
      <p>If you believe this restriction is in error or requires review, please contact the LU Serve office at:
        <a href="mailto:cser@liberty.edu">cser&#64;liberty.edu</a>.
      </p>
    </section>

    <footer>
      <p>For additional information about LU Serve programs and services, please visit our
        <a href="https://www.liberty.edu/cser" class="external-link">official webpage</a>.
      </p>
    </footer>
  </section>
</main>

import {ITermEventState} from "./term-event.state";
import {Action, createReducer, on} from "@ngrx/store";
import {
  getTermEventsAction,
  getTermEventsFailureAction,
  getTermEventsSuccessAction,
  postTermEventAction,
  postTermEventFailureAction,
  postTermEventSuccessAction,
  updateTermEventAction,
  updateTermEventFailureAction,
  updateTermEventSuccessAction
} from "./term-event.actions";
import {stateHelperFunctions} from "../stateHelperFunctions";

export const initialTermEventState: ITermEventState = {
  termEvents: [],
  loading: false,
  error: undefined
}

const termEventReducer = createReducer(
  initialTermEventState,

  on(getTermEventsAction,
    postTermEventAction,
    updateTermEventAction,
    (state, action): ITermEventState => ({
      ...state,
      loading: true,
      error: undefined
  })),

  on(getTermEventsSuccessAction,
    postTermEventSuccessAction,
    (state, action): ITermEventState => ({
    ...state,
    termEvents: state.termEvents.concat(action.data),
    loading: false,
    error: undefined
  })),

  on(updateTermEventSuccessAction, (state, action): ITermEventState => ({
    ...state,
    termEvents: stateHelperFunctions.replaceItemInArray(state.termEvents, action.data),
    loading: false,
    error: undefined
  })),

  on(getTermEventsFailureAction,
    postTermEventFailureAction,
    updateTermEventFailureAction,
    (state, action): ITermEventState => ({
    ...state,
    loading: false,
    error: action.error.error
  })),

);

export function termEventReducers(state: ITermEventState, action: Action) {
  return termEventReducer(state, action);
}

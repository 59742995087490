import {Component, OnInit} from '@angular/core';
import {TableColumn, TableData} from "../../shared/model/tableData.model";
import {RegisterService} from "../../core/service/register.service";
import {IRetryData} from "../../shared/model/retry-data.model";
import {MessageService} from "../../core/service/message.service";
import {BehaviorSubject, finalize, tap} from "rxjs";

@Component({
  selector: 'app-failed-submissions',
  templateUrl: './failed-submissions.component.html',
  styleUrl: './failed-submissions.component.scss'
})
export class FailedSubmissionsComponent implements OnInit {
  public tableColumns!: TableColumn[];
  public tableData!: TableData[];
  public failedRegistrations!: IRetryData[];
  public isProcessing$ = new BehaviorSubject<boolean>(false);


  constructor(private registerService: RegisterService, private messageService: MessageService) {}

  ngOnInit() {
    this.tableColumns = [
      { header: 'date', field: 'date', sortDirection: 'asc', dataType: 'string' },
      { header: 'error type', field: 'errorType', sortDirection: 'asc', dataType: 'string' },
      { header: '', field: 'resolution', sortDirection: 'asc', dataType: undefined }
    ]

    this.registerService.getFailedRegistrations().subscribe((data: IRetryData[]) => {
      this.failedRegistrations = data;
      this.tableData = data.map((retry: IRetryData) => {
        return {
          id: retry.serviceRegistrationId,
          date: retry.activityDate,
          errorType: retry.errorMessage,
          isSelected: false,
          isExpanded: false
        };
      });
    });
  }

  public retrySubmission($event: string) {
    if (this.isProcessing$.value) { return; }

    const failedRegistration: IRetryData | undefined = this.failedRegistrations.find((retry: IRetryData) => retry.serviceRegistrationId === $event)
    if (failedRegistration) {
      this.isProcessing$.next(true);
      this.registerService.retryFailedRegistration(failedRegistration).pipe(
        finalize(() => { this.isProcessing$.next(false) }),
      ).subscribe({
        next: (retryData: IRetryData) => {
          this.messageService.showMessage(`You have successfully registered the student for ${failedRegistration.opportunityName}`);
          this.tableData = this.tableData.filter((data: TableData) => data.id !== failedRegistration.serviceRegistrationId);
        }, error: (error: {error: {error: string}}) => {
          this.messageService.showMessage(error.error.error);
        }
      })
    }
  }

  public viewSubmissionError($event: string) {
    console.log('handle view submission error');
  }
}

import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatLatestFrom} from '@ngrx/operators';
import {select, Store} from '@ngrx/store';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {delay, filter, of, switchMap, take, tap} from 'rxjs';
import {OrganizationRepository} from '../../../repository/organization.repository';
import {MessageService} from '../../../service/message.service';
import {
  getMultipleOrganizationsAction,
  getMultipleOrganizationsFailureAction,
  getMultipleOrganizationsSuccessAction,
  getOrganizationAction,
  getOrganizationFailureAction,
  getOrganizationSuccessAction,
  getSupervisorOrganizationsAction,
  getSupervisorOrganizationsFailureAction,
  getSupervisorOrganizationsSuccessAction,
  resetSuccessfulUpdateAction,
  updateOrganizationAction,
  updateOrganizationFailureAction,
  updateOrganizationSuccessAction
} from '../organization.actions';
import {
  getIdsToLoadSelector,
  getOrganizationSelector,
  isLoadingDuplicateOrganizationSelector
} from '../organization.selectors';
import {searchOpportunitiesSuccessAction} from "../../opportunity/opportunity.actions";
import {SupervisorRepository} from "../../../repository/supervisor.repository";

@Injectable()
export class GetOrganizationEffect {
  constructor(
    private actions$: Actions,
    private store: Store,
    private orgRepo: OrganizationRepository,
    private supervisorRepo: SupervisorRepository,
    private messageService: MessageService,
    private organizationRepo: OrganizationRepository,
  ) {
  }

  // noinspection TypeScriptValidateTypes
  getOrganization$ = createEffect(() => this.actions$.pipe(
    ofType(getOrganizationAction),
    concatLatestFrom((action) => this.store.pipe(select(isLoadingDuplicateOrganizationSelector(action.id)))),
    filter(([, isDuplicate]) => !isDuplicate),
    mergeMap(([action,]) => {
      return this.orgRepo.getOrganization(action.id).pipe(
        map(response => getOrganizationSuccessAction({data: response})),
        catchError((errorResponse: HttpErrorResponse) => of(getOrganizationFailureAction({
          error: errorResponse,
          id: action.id
        })))
      );
    })
  ));

  // noinspection TypeScriptValidateTypes
  getMultipleOrganizations$ = createEffect(() => this.actions$.pipe(
    ofType(getMultipleOrganizationsAction),
    concatLatestFrom((action) => this.store.pipe(select(getIdsToLoadSelector(action.ids)))),
    mergeMap(([action, idsToLoad]) => {
      if (!idsToLoad?.length) {
        return of(searchOpportunitiesSuccessAction({data: action.opportunities}));
      }
      return this.orgRepo.getMultipleOrganizations(idsToLoad).pipe(
        map(response => getMultipleOrganizationsSuccessAction({
          data: response,
          ids: idsToLoad,
          opportunities: action.opportunities
        })),
        catchError((errorResponse: HttpErrorResponse) => of(getMultipleOrganizationsFailureAction({
          error: errorResponse,
          ids: idsToLoad,
          opportunities: action.opportunities
        })))
      );
    })
  ));

  // noinspection TypeScriptValidateTypes
  getMultipleOrganizationsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(getMultipleOrganizationsSuccessAction, getMultipleOrganizationsFailureAction),
    switchMap((action) => of(searchOpportunitiesSuccessAction({data: action.opportunities})))
  ));

  // noinspection TypeScriptValidateTypes
  updateOrganization$ = createEffect(() => this.actions$.pipe(
    ofType(updateOrganizationAction),
    concatLatestFrom((action) => this.store.select(getOrganizationSelector(action.data.id))),
    mergeMap(([action, prevOrg]) => {
      return this.orgRepo.updateOrganization(action.data).pipe(
        map(response => updateOrganizationSuccessAction({
          data: response,
          isLeadChanging: !prevOrg || action.data.supervisorId != prevOrg.supervisorId
        })),
        catchError((errorResponse: HttpErrorResponse) => of(updateOrganizationFailureAction({
          error: errorResponse,
          id: action.data.id
        })))
      )
    })
  ))

  // noinspection TypeScriptValidateTypes
  updateOrganizationSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(updateOrganizationSuccessAction),
    tap(response => {
      let currentSupervisor: String;
      if ('organizationChanges' in response.data) {
        this.messageService.showMessage("A request to update your organization has been submitted to be approved by LU Serve staff.");
        currentSupervisor = response.data.organizationChanges.supervisorId;
      } else {
        this.messageService.showMessage("This organization has been updated.");
        currentSupervisor = response.data.supervisorId;
      }
      // if supervisorID != currentSupervisor => reload
      this.supervisorRepo.getCurrentSupervisor().pipe(take(1)).subscribe((supervisor) => {
        if (response.isLeadChanging && currentSupervisor !== supervisor.id) {
          window.location.reload();
        }
      })
    }),
    delay(1000),
    mergeMap((action) => {
      return of(resetSuccessfulUpdateAction());
    })
  ))

  // noinspection TypeScriptValidateTypes
  updateOrganizationFailure$ = createEffect(() => this.actions$.pipe(
    ofType(updateOrganizationFailureAction),
    tap((action) => {
      if (action?.error?.error?.error?.includes("cannot be assigned")) {
        this.messageService.showErrorMessage(action?.error?.error?.error);
      } else {
        this.messageService.showErrorMessage("Unable to update organization at this time. Please try again later.")
      }
    })
  ), {dispatch: false})

  getSupervisorOrganizations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSupervisorOrganizationsAction),
      switchMap(action =>
        this.organizationRepo.getOrganizationsFromLeadId(action.supervisorId).pipe(
          map(organizations => getSupervisorOrganizationsSuccessAction({ data: organizations })),
          catchError(error => of(getSupervisorOrganizationsFailureAction({ error })))
        )
      )
    )
  );
}

import {Injectable} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {IUserInfo, IUserInfoComplete} from '../../shared/model/userInfo.model';

const BASE_URL: string = `${environment.apiUrl}`

@Injectable({providedIn: 'root'})
export class AuthRepository {

  public readonly impersonateUrl: string = `${environment.apiUrl}/switch-user?username=`;
  public readonly emailDomain: string = `${environment.emailDomain}`;

  constructor(
    private http: HttpClient,
  ) { }

  public getUserInfo(): Observable<IUserInfoComplete> {
    return this.http.get<IUserInfoComplete>(`${BASE_URL}/user-info`);
  }

  public updateUserInfo(userInfo: IUserInfo): Observable<IUserInfo> {
    return this.http.put<IUserInfo>(`${BASE_URL}/user/${userInfo.id}`, userInfo);
  }

  public impersonateUser(email: string): Observable<IUserInfoComplete> {
    if (email.indexOf('@') < 0) {
      email = email + this.emailDomain;
    }
    return this.http.post<IUserInfoComplete>(`${this.impersonateUrl}${email}`, null);
  }

  public stopImpersonating(): Observable<IUserInfoComplete> {
    return this.http.post<IUserInfoComplete>(`${environment.apiUrl}/exit-user`, null);
  }
}

<header class="w-100 h-100">
  <nav class="f-col h-100 justify-content-between">
    <div class="top f-col g-4" *ngIf="(isContractSigned$ | async) || (isImpersonating$ | async)">
      <div class="heading d-flex p-4 align-items-center">
        <img class="serve-logo" src="assets/Logo.png" alt="LU Serve Logo">
        <div class="role-text x-small-reg-text">SUPERVISOR</div>
      </div>

      <div class="my-sections f-col" *ngIf="organizations.length > 0 || (opportunities$ | async)!.length > 0; else no_organization">
        <div class="nav-list f-col" *ngIf="organizations.length > 0">
          <div class="d-flex justify-content-between title align-items-center">
            <div class="xx-small-gray-text">My Organizations</div>
            <app-icon icon="plus" class="plus small-icon" [routerLink]="['/','supervisor','organization','add']" matTooltip="Request a new organization."></app-icon>
          </div>
          <div class="opportunity d-flex" *ngFor="let org of organizations" routerLinkActive="active-page" [routerLink]="getOrgRoute(org)">
            <div class="logo-and-name d-inline-flex align-items-center gap-2">
<!--PHASE 2              <img class="org-img" src="assets/icons/LU.jpg" alt="">-->
              <div class="small-medium-text" [ngClass]="org.status === OrganizationStatus.ACTIVE ? '' : 'not-active-organization'">{{org.name}}</div>
              <div class="status-tag x-small-medium-text" *ngIf="org.status !== OrganizationStatus.ACTIVE">{{orgStatusDisplay(org.status)}}</div>
            </div>
          </div>
        </div>

        <div class="nav-list f-col" *ngIf="(opportunities$! | async)!.length > 0">
          <div class="title xx-small-gray-text">My Current Opportunities</div>
          <div class="opportunity d-flex align-items-center gap-2" *ngFor="let opp of (opportunities$! | async)" routerLinkActive="active-page" [routerLink]="['/', 'supervisor', 'my-opportunities', opp.id]">
            <div class="logo-and-name d-flex align-items-center gap-2">
<!--PHASE 2              <img class="org-img" src="assets/icons/LU.jpg" alt="">-->
              <div class="small-medium-text">{{opp.name}}</div>
            </div>
          </div>
        </div>

<!--        Remove this condition when we go live for all users to show this section. -->
        @if (false) {
          <div class="opportunity" routerLinkActive="active-page">
            <button class="link-button small-bold-text d-flex gap-2 align-items-center border-0" [routerLink]="['/', 'supervisor', 'past-opportunities']">
              <app-icon icon="history" class="xxxxl-icon"></app-icon>
              Past Opportunities</button>
          </div>
        }
      </div>

      <ng-template #no_organization>
        <div class="nav-list f-col">
          <div class="title">
            <div class="xx-small-gray-text">My Organizations</div>
          </div>
          <div class="new-org">
            <button class="custom-button-large-primary-dark-blue new-org-button" [routerLink]="['/','supervisor','organization','add']">New Organization Request</button>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="footer f-col p-4">
      <div class="supervisor-details d-flex align-items-center gap-3">
<!--PHASE 2        <img src="assets/images/user.jpg" alt="No photo provided" class="supervisor-picture">-->
        <div class="contact-info f-col gap-1 overflow-hidden">
          <div class="supervisor-name medium-bold-text text-wrap text-break">{{userName}}</div>
          <a href="mailto:{{userEmail}}" class="email small-email-text">{{userEmail}}</a>
        </div>
      </div>
      <button *ngIf="(isContractSigned$ | async) || (isImpersonating$ | async)" class="supervisor-button custom-button-large-secondary small-medium-text gap-2" [routerLink]="['/', 'supervisor', 'edit-profile']">
        <app-icon icon="edit" class="edit-icon small-icon"></app-icon>
          Edit Profile</button>
      <button *ngIf="(isContractSigned$ | async) || (isImpersonating$ | async)" class="supervisor-button logOut custom-button-large-secondary small-medium-text" [routerLink]="['/']">Student</button>
      <button *ngIf="isAdmin$ | async" class="supervisor-button logOut custom-button-large-secondary small-medium-text" [routerLink]="['/','admin']">Admin</button>
      <button class="supervisor-button logOut custom-button-large-secondary small-medium-text" (click)="logOut()">Log Out</button>
    </div>
  </nav>
</header>

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'Mo',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'Mo',
    dayLabel: 'dd'
  },
};

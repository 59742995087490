import {Component, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {AuthService} from '../../core/service/auth.service';
import {UserService} from '../../core/service/user.service';

@Component({
  selector: 'app-admin-side-bar',
  templateUrl: './admin-side-bar.component.html',
  styleUrls: ['./admin-side-bar.component.scss']
})
export class AdminSideBarComponent implements OnInit{
  public userName!: string | undefined;
  public userEmail!: string | undefined;
  public isAdmin$!: Observable<boolean | undefined>;
  public isSupervisor$!: Observable<boolean | undefined>;
  public modalOpen = false;

  constructor(private authService: AuthService, private userService: UserService) { }

  ngOnInit() {
    this.authService.getCurrentUserInfo().subscribe(userInfo => {
      this.userName = userInfo?.currentUser.displayName;
      this.userEmail = userInfo?.currentUser.email;
    });

    this.isAdmin$ = this.authService.isAdmin();
    this.isSupervisor$ = this.authService.isSupervisor();
  }

  public openImpersonateModal() {
    this.modalOpen = true;
  }

  public onModalClose() {
    this.modalOpen = false;
  }

  public logOut(): void {
    this.userService.signOut();
  }
}

import {ActivatedRouteSnapshot, Params, RouterStateSnapshot} from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import {RouterStateUrl} from './router.state';


//Example found here: https://ngrx.io/guide/router-store/configuration
export class ServeRouterStateSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const { url } = routerState;
    const params: Params = this.collectRouteParams(routerState.root);
    const queryParams = routerState.root.queryParams;

    return { url, params, queryParams };
  }

  private collectRouteParams(route: ActivatedRouteSnapshot, depth: number = 0): Params {
    const params: Params = { ...route.params, _routeDepth: depth };

    if (route.firstChild) {
      return { ...params, ...this.collectRouteParams(route.firstChild, depth + 1) };
    }

    return params;
  }
}

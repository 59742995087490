import {IOpportunityConfigState} from "./opportunity-config.state";
import {Action, createReducer, on} from "@ngrx/store";
import {
  addOpportunityAnnouncementRecordAction,
  addDroppedUserRecordAction,
  addRegistrationRequestRecordAction,
  addSupervisorEvalPendingRecordAction,
  addSupervisorRecordAction,
  addVolunteerRecordAction,
  clearOpportunityDataAction,
  getCurrentSupervisorFailureAction,
  getCurrentSupervisorSuccessAction,
  removeDroppedUserRecordAction,
  removeOpportunityAnnouncementRecordAction,
  removeRegistrationRequestRecordAction,
  removeSupervisorEvalPendingRecordAction,
  removeSupervisorRecordAction,
  removeVolunteerRecordAction,
  setCurrentSupervisorAction,
  setIsLeadSupervisorAction,
  setOpportunityDataAction,
  updateOpportunityAnnouncementRecordAction,
  updateRegistrationRequestRecordAction,
  updateVolunteerRecordAction,
  getOpportunityAnnouncementRecordsSuccessAction,
  addDeniedUserRecordAction,
  removeDeniedUserRecordAction,
  addSubmittedEvalRecordAction,
  removeSubmittedEvalRecordAction
} from './opportunity-config.actions';
import {stateHelperFunctions} from "../stateHelperFunctions";

export const initialOpportunityConfigState: IOpportunityConfigState = {
  opportunityData: undefined,
  volunteerRecords: [],
  supervisorRecords: [],
  registrationRequestCount: 0,
  registrationRequestRecords: [],
  supervisorEvalPendingCount: 0,
  supervisorEvalPendingRecords: [],
  submittedEvalRecords: [],
  droppedUserRecords: [],
  deniedUserRecords: [],
  maxVolunteers: 0,
  currentSupervisor: undefined,
  isLead: false,
  announcements: [],
  error: undefined
};

const opportunityConfigReducer = createReducer (
  initialOpportunityConfigState,

  on (setCurrentSupervisorAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      currentSupervisor: action.supervisor,
    })
  ),

  on (getCurrentSupervisorSuccessAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      currentSupervisor: action.data
    })
  ),

  on (getCurrentSupervisorFailureAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      error: action.error.error
    })
  ),

  on (setIsLeadSupervisorAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      isLead: action.isLead,
    })
  ),

  on (addRegistrationRequestRecordAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      registrationRequestRecords: state.registrationRequestRecords.concat([action.record]),
    })
  ),

  on (updateRegistrationRequestRecordAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      registrationRequestRecords: stateHelperFunctions.replaceItemInArray(state.registrationRequestRecords, action.record),
    })
  ),

  on (removeRegistrationRequestRecordAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      registrationRequestRecords: stateHelperFunctions.removeItemInArray(state.registrationRequestRecords, action.record),
    })
  ),

  on (addSupervisorEvalPendingRecordAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      supervisorEvalPendingRecords: state.supervisorEvalPendingRecords.concat([action.record]),
    })
  ),

  on (removeSupervisorEvalPendingRecordAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      supervisorEvalPendingRecords: stateHelperFunctions.removeItemInArray(state.supervisorEvalPendingRecords, action.record),
    })
  ),

  on (addDroppedUserRecordAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      droppedUserRecords: state.droppedUserRecords.concat([action.record]),
    })
  ),

  on (removeDroppedUserRecordAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      droppedUserRecords: stateHelperFunctions.removeItemInArray(state.droppedUserRecords, action.record),
    })
  ),

  on (addSubmittedEvalRecordAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      submittedEvalRecords: state.submittedEvalRecords.concat([action.record]),
    })
  ),

  on (removeSubmittedEvalRecordAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      submittedEvalRecords: stateHelperFunctions.removeItemInArray(state.submittedEvalRecords, action.record),
    })
  ),

  on (addDeniedUserRecordAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      deniedUserRecords: state.deniedUserRecords.concat([action.record]),
    })
  ),

  on (removeDeniedUserRecordAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      deniedUserRecords: stateHelperFunctions.removeItemInArray(state.deniedUserRecords, action.record),
    })
  ),

  on (addVolunteerRecordAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      volunteerRecords: state.volunteerRecords.concat([action.record]),
    })
  ),

  on (updateVolunteerRecordAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      volunteerRecords: stateHelperFunctions.replaceItemInArray(state.volunteerRecords, action.record),
    })
  ),

  on (removeVolunteerRecordAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      volunteerRecords: stateHelperFunctions.removeItemInArray(state.volunteerRecords, action.record),
    })
  ),

  on (addSupervisorRecordAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      supervisorRecords: state.supervisorRecords.concat([action.record]),
    })
  ),

  on (removeSupervisorRecordAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      supervisorRecords: stateHelperFunctions.removeItemInArray(state.supervisorRecords, action.record),
    })
  ),

  on (setOpportunityDataAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      opportunityData: action.opportunity,
      maxVolunteers: Number(action.opportunity.maxStudents)
    })
  ),

  on (clearOpportunityDataAction,
    (state): IOpportunityConfigState => ({
      ...initialOpportunityConfigState,
      currentSupervisor: state.currentSupervisor
    })
  ),

  on (addOpportunityAnnouncementRecordAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      announcements: state.announcements.concat([action.record]),
    })
  ),

  on (updateOpportunityAnnouncementRecordAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      announcements: stateHelperFunctions.replaceItemInArray(state.announcements, action.record),
    })
  ),

  on (removeOpportunityAnnouncementRecordAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      announcements: stateHelperFunctions.removeItemByIdInArray(state.announcements, action.record),
    })
  ),

  on (getOpportunityAnnouncementRecordsSuccessAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      announcements: action.data
    })
  ),

  on (getCurrentSupervisorFailureAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      error: action.error.error
    })
  ),
);

export function opportunityConfigReducers(state: IOpportunityConfigState, action: Action) {
  return opportunityConfigReducer(state, action);
}


<header>
  <nav class="f-col">
    <div class="top f-col">
      <div class="heading">
        <img class="serve-logo" src="assets/logo-white.svg" alt="LU Serve Logo">
        <div class="role-text x-small-reg-text">ADMINISTRATOR</div>
      </div>
      <div class="nav-list f-col">

        <button id="users-tab" class="title"
                routerLinkActive="admin-active-page" [routerLink]="['/', 'admin', 'users']">
          <app-icon icon="user" class="large-icon"></app-icon>
          <span class="small-medium-text">Users</span>
        </button>

        <button class="title"
                routerLinkActive="admin-active-page" [routerLink]="['/', 'admin', 'organizations']">
          <app-icon icon="globe" class="large-icon"></app-icon>
          <span class="small-medium-text">Organizations</span>
        </button>

        <button id="supervisor" class="title"
                routerLinkActive="admin-active-page" [routerLink]="['/', 'admin', 'supervisors']">
          <app-icon icon="users" class="large-icon"></app-icon>
          <span class="small-medium-text">Supervisors</span>
        </button>

        <button id="volunteers" class="title"
                routerLinkActive="admin-active-page" [routerLink]="['/', 'admin', 'placements']">
          <app-icon icon="heart-outline" class="large-icon"></app-icon>
          <span class="small-medium-text">Placements</span>
        </button>

        <hr>

        <button id="approvals" class="title approvals"
                routerLinkActive="admin-active-page" [routerLink]="['/', 'admin', 'approvals']">
            <span class="icon-and-title f-row">
              <app-icon icon="check-square" class="large-icon"></app-icon>
              <span class="small-medium-text">Approvals</span>
            </span>
        </button>
        <button id="announcements" class="title announcements"
                routerLinkActive="admin-active-page" [routerLink]="['/', 'admin', 'announcements']">
            <span class="icon-and-title f-row">
              <app-icon icon="megaphone" class="large-icon"></app-icon>
              <span class="small-medium-text">Announcements</span>
            </span>
        </button>
        <button id="termEvents" class="title termEvents"
                routerLinkActive="admin-active-page" [routerLink]="['/', 'admin', 'terms']">
            <span class="icon-and-title f-row">
               <app-icon icon="settings-cog" class="large-icon"></app-icon>
              <span class="small-medium-text">Terms</span>
            </span>
        </button>
        <button id="failedSubmissions" class="title failedSubmissions"
                routerLinkActive="admin-active-page" [routerLink]="['/', 'admin', 'failed-submissions']">
            <span class="icon-and-title f-row">
                <app-icon icon="alert-circle" class="large-icon"></app-icon>
                <span class="small-medium-text">Failed Submissions</span>
            </span>
        </button>

        <hr>

<!--        <button id="uploads" class="title uploads"-->
<!--                routerLinkActive="admin-active-page" [routerLink]="['/', 'admin', 'uploads']"-->
<!--                *ngIf="isAdmin$ | async">-->
<!--            <span class="icon-and-title f-row">-->
<!--              <app-icon icon="upload" class="large-icon"></app-icon>-->
<!--              <span class="small-medium-text">Uploads</span>-->
<!--            </span>-->
<!--        </button>-->

        <button id="settings" class="title settings"
                routerLinkActive="admin-active-page" [routerLink]="['/','admin','settings']">
            <span class="icon-and-title f-row">
              <app-icon icon="settings-cog" class="large-icon"></app-icon>
              <span class="small-medium-text">Global Settings</span>
            </span>
        </button>

      </div>
    </div>

    <div class="footer f-col">
      <div class="admin-info f-col">
          <div class="supervisor-name small-bold-text">{{userName}}</div>
          <a href="{{userEmail}}" class="email small-email-text">{{userEmail}}</a>
      </div>
      <button class="custom-button-large-primary-admin-blue small-medium-text" [routerLink]="['/']">Student</button>
      <button *ngIf="isSupervisor$ | async"
              class="custom-button-large-primary-admin-blue small-medium-text"
              [routerLink]="['/','supervisor']">Supervisor</button>
      <button class="custom-button-large-primary-admin-blue small-medium-text" (click)="openImpersonateModal()">Impersonate</button>
      <button class="custom-button-large-primary-admin-blue small-medium-text" (click)="logOut()">Log Out</button>
    </div>
  </nav>
</header>

<app-impersonate-modal *ngIf="modalOpen" (closeModal)="onModalClose()"></app-impersonate-modal>

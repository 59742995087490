<div class="btn-group" dropdown #dropdown="bs-dropdown" [autoClose]="true">
  <button id="button-basic" type="button"
          class="btn custom-button-small-secondary dropdown-button"
          dropdownToggle
          aria-controls="dropdown-basic" data-cy="username"
          [ngClass]="{ 'mobile-button' : canShowUserIcon$ | async }"
          aria-haspopup="true" attr.aria-label="{{ userName$ | async }} press enter or click to access menu items."  >
    <ng-container *ngIf="canShowUserIcon$ | async">
      <app-icon icon="user" class="small-icon" [ngClass]="{ 'white' : dropdown.isOpen }"></app-icon>
    </ng-container>
    <span class="user-name-text" *ngIf="!(canShowUserIcon$ | async)">{{ userName$ | async }}</span>
    <app-icon *ngIf="!(canShowUserIcon$ | async)" icon="chevron-down" class="chevron-down" [ngClass]="{ 'white' : dropdown.isOpen }"></app-icon>
  </button>
  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu">
    @if (canShowUserIcon$ | async) {
      <li class="menuitem" id="credit-progress">
        <button class="custom-button-small-secondary" [routerLink]="['/', 'audit']">
          <div class="dropdown-item" [routerLink]="['/', 'audit']">
            <app-icon icon="pie-chart" class="x-small-icon"></app-icon>
            <span class="progress-text">{{ creditProgressText }}</span>
          </div>
        </button>
      </li>
    }
    <li role="menuitem" aria-label="Impersonate" *ngIf="canImpersonate$ | async">
      <div class="dropdown-item"  (click)="openModal()" (keydown.enter)="openModal()" tabindex="0" data-cy="impersonate">Impersonate</div>
    </li>
    <li role="menuitem" aria-label="Admin Dashboard" *ngIf="isAdmin$ | async">
      <a class="dropdown-item"  [routerLink]="['/', 'admin']">Admin Dashboard</a>
    </li>
    <li role="menuitem" aria-label="Supervisor Dashboard" *ngIf="isSupervisor$ | async">
      <a class="dropdown-item" [routerLink]="['/', 'supervisor']">Supervisor Dashboard</a>
    </li>
    <li role="menuitem" aria-label="Request CSER 100" *ngIf="needsCSER100$ | async">
      <a class="dropdown-item" href="https://apex.liberty.edu/banprd/f?p=253:14:::NO:RP:FORM_NAME:CSER_100" target="_blank">Request CSER 100</a>
    </li>

    <li class="divider dropdown-divider" *ngIf="isAdmin$ | async"></li>
    <li role="menuitem" aria-label="logout" >
      <div class="dropdown-item" (click)="logOut()" (keydown.enter)="logOut()" tabindex="0" data-cy="logout">Log Out</div>
    </li>
  </ul>
</div>
<app-impersonate-modal *ngIf="modalOpen" (closeModal)="onModalClose()"></app-impersonate-modal>

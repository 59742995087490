import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatLatestFrom} from '@ngrx/operators';
import {select, Store} from "@ngrx/store";
import {SupervisorRepository} from "../../../repository/supervisor.repository";
import {AgreementRepository} from "../../../repository/agreement.repository"
import {
  getHasSignedAction,
  getHasSignedFailureAction,
  getHasSignedSuccessAction,
  getUserSupervisorOpportunitiesAction,
  getUserSupervisorOpportunitiesFailureAction,
  getUserSupervisorOpportunitiesSuccessAction,
  saveAgreementAction,
  saveAgreementFailureAction,
  saveAgreementSuccessAction
} from "../user-supervisor.actions";
import {catchError, exhaustMap, filter, map, of} from "rxjs";
import {getUserSupervisorOpportunitiesSelector} from "../user-supervisor.selectors";
import {opportunitySort} from "../../../../shared/function/opportunity-sort";

@Injectable()
export class UserSupervisorEffect {
  constructor(private actions$: Actions,
    private store: Store,
    private repo: SupervisorRepository,
    private agreementRepo: AgreementRepository) {
  }

  // noinspection TypeScriptValidateTypes
  getOpportunities$ = createEffect(() => this.actions$.pipe(
    ofType(getUserSupervisorOpportunitiesAction),
    concatLatestFrom((action) => this.store.pipe(select(getUserSupervisorOpportunitiesSelector))),
    filter(([, currentData]) => !currentData.length),
    exhaustMap(([action,]) => {
      return this.repo.getSupervisedOpportunitiesFromCurrentUser().pipe(
        map(response => response?.length ? response.sort(opportunitySort) : response),
        map(response => getUserSupervisorOpportunitiesSuccessAction({ data: response })),
        catchError((errorResponse) => of(getUserSupervisorOpportunitiesFailureAction({ error: errorResponse })))
      )
    })
  ));

  getHasSigned$ = createEffect(() => this.actions$.pipe(
    ofType(getHasSignedAction),
    exhaustMap(() => {
      return this.repo.getCurrentSupervisor().pipe(
        map(response => getHasSignedSuccessAction({ status: response.isContractSigned })),
        catchError((errorResponse) => of(getHasSignedFailureAction({ error: errorResponse })))
      )
    })
  ));

  saveAgreement$ = createEffect(() => this.actions$.pipe(
    ofType(saveAgreementAction),
    exhaustMap((action) => {
      return this.agreementRepo.saveAgreement(action.agreementData).pipe(
        map(response => saveAgreementSuccessAction()),
        catchError((errorResponse) => of(saveAgreementFailureAction({ error: errorResponse })))
      )
    })
  ))
}

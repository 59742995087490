import {IOpportunity} from "../model/opportunity.model";

export function opportunitySort(a: IOpportunity, b: IOpportunity) {
  const termComparison = parseInt(b.term) - parseInt(a.term);
  if (termComparison !== 0) {
    return termComparison;
  }

  return a.name.localeCompare(b.name);
}

import {Action, createReducer, on} from '@ngrx/store';
import {
  endImpersonateUserAction,
  endImpersonateUserSuccessAction,
  getUserInfoAction,
  getUserInfoFailureAction,
  getUserInfoLoadedAction,
  getUserInfoSuccessAction,
  impersonateUserAction,
  impersonateUserFailureAction,
  impersonateUserSuccessAction,
  logoutEndImpersonateUserAction,
  switchUserAction,
  switchUserFailureAction,
  switchUserSuccessAction,
  updateUserInfoAction,
  updateUserInfoFailureAction,
  updateUserInfoSuccessAction,
} from './auth.actions';
import {IAuthState} from './auth.state';


export const initialAuthState: IAuthState = {
    userInfo: undefined,
    isLogout: false,
    isLoadingUserData: false,
    error: undefined,
    impersonateDestination: undefined,
};

const authReducer = createReducer(
    initialAuthState,

    /*
    This checks to see if the user info property has already been loaded into the state.
    If it has, then we just return the current state. We know that the effect on this action won't try to load anything
    from the API, so we don't want our state to show that it's loading anything.
    Otherwise, we'll set the loading indicator because we're going to have to load it, and then either the Success or
    Failure will reset it once it is finished.
     */
    on(getUserInfoAction,
        (state): IAuthState => {
            return !!state.userInfo ? state : ({
                ...state,
                isLoadingUserData: true
            })
        }
    ),

    on(updateUserInfoAction,
      (state): IAuthState => ({
        ...state,
        isLoadingUserData: true
      })
    ),

    on(updateUserInfoSuccessAction,
      (state, action): IAuthState => ({
        ...state,
        userInfo: {
          ...state.userInfo!,
          currentUser: action.data
        },
        isLoadingUserData: false
      })
    ),

    on(getUserInfoSuccessAction,
        (state, action): IAuthState => ({
            ...state,
            userInfo: action.data
        })
    ),

    on(getUserInfoLoadedAction,
      (state, action): IAuthState => ({
        ...state,
        isLoadingUserData: false,
      })
    ),

    on(getUserInfoFailureAction,
        updateUserInfoFailureAction,
        (state, action): IAuthState => ({
            ...state,
            isLoadingUserData: false,
            error: action.error.error
        })
    ),

    on(impersonateUserSuccessAction,
        endImpersonateUserSuccessAction,
        (state, action): IAuthState => ({
            ...state,
            // isLoadingUserData: false, //removing because we want the loading screen to persist up to the reload
            userInfo: action.data
        })
    ),

    on(switchUserSuccessAction,
        (state, action): IAuthState => ({
            ...state,
            isLoadingUserData: false,
            userInfo: action.data
        })
    ),

    on(impersonateUserAction,
        (state, action): IAuthState => ({
            ...state,
            isLoadingUserData: true,
            impersonateDestination: action.destination,
        })
    ),

    on(switchUserAction,
      endImpersonateUserAction,
        (state): IAuthState => ({
            ...state,
            isLoadingUserData: true,
        })
    ),

    on(logoutEndImpersonateUserAction,
      (state): IAuthState => ({
        ...state,
        isLogout: true,
      })
    ),

    on(impersonateUserFailureAction,
        switchUserFailureAction,
        (state): IAuthState => ({
            ...state,
            isLoadingUserData: false
        })
    ),

);

export function authReducers(state: IAuthState, action: Action) {
    return authReducer(state, action);
}

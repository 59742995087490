import {Action, createReducer, on} from '@ngrx/store';
import {stateHelperFunctions} from '../stateHelperFunctions';
import {
  getOpportunityAction,
  getOpportunityFailureAction,
  getOpportunitySuccessAction,
  searchOpportunitiesAction,
  searchOpportunitiesFailureAction,
  searchOpportunitiesSuccessAction,
  updateOpportunityAction,
  updateOpportunityFailureAction,
  updateOpportunitySuccessAction
} from './opportunity.actions';
import {IOpportunityState} from './opportunity.state';
import {getUserSupervisorOpportunitiesSuccessAction} from "../user-supervisor/user-supervisor.actions";


export const initialOpportunityState: IOpportunityState = {
  opportunities: [],
  lastSearchResults: [],
  loadingOpportunities: [],
  runningSearch: false,
  error: undefined
}

const opportunityReducer = createReducer(
  initialOpportunityState,

  on(getOpportunityAction, (state, action): IOpportunityState => {
      return stateHelperFunctions.hasItem(state, action.id) ? state : ({
        ...state,
        loadingOpportunities: state.loadingOpportunities.concat([action.id])
      })
    }
  ),

  on(updateOpportunityAction, (state, action): IOpportunityState => {
    return stateHelperFunctions.hasItem(state, action.data.id) ? state : ({
      ...state,
      loadingOpportunities: state.loadingOpportunities.concat([action.data.id])
    })
  }),

  on(getOpportunitySuccessAction, (state, action): IOpportunityState => ({
      ...state,
      loadingOpportunities: stateHelperFunctions.removeLoadingId(state.loadingOpportunities, action.data.id),
      opportunities: state.opportunities?.concat(action.data)
    })
  ),

  on(getOpportunityFailureAction,
    updateOpportunityFailureAction,
    (state, action): IOpportunityState => ({
      ...state,
      loadingOpportunities: stateHelperFunctions.removeLoadingId(state.loadingOpportunities, action.id),
      error: action.error.error
    })
  ),

  on(searchOpportunitiesAction, (state, action): IOpportunityState => ({
      ...state,
      runningSearch: true
    })
  ),

  on(searchOpportunitiesSuccessAction, (state, action): IOpportunityState => ({
      ...state,
      runningSearch: false,
      opportunities: state.opportunities?.concat(action.data.filter(result => !state.opportunities.some(opp => opp.id === result.id))),
      lastSearchResults: action.data
    })
  ),

  on(searchOpportunitiesFailureAction, (state, action): IOpportunityState => ({
      ...state,
      runningSearch: false,
      error: action.error.error
    })
  ),

  on(updateOpportunitySuccessAction, (state, action): IOpportunityState => ({
      ...state,
      loadingOpportunities: stateHelperFunctions.removeLoadingId(state.loadingOpportunities, action.data.id),
      opportunities: stateHelperFunctions.replaceItemInArray(state.opportunities, action.data),
      error: undefined
    })
  ),

  on(getUserSupervisorOpportunitiesSuccessAction, (state, action): IOpportunityState => ({
    ...state,
    opportunities: state.opportunities?.concat(action.data.filter(result => !state.opportunities.some(opp => opp.id === result.id))),
    error: undefined,
  }))
);

export function opportunityReducers(state: IOpportunityState, action: Action) {
  return opportunityReducer(state, action);
}

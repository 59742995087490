import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EMPTY, Observable, of, switchMap, throwError} from 'rxjs';
import {catchError, take} from 'rxjs/operators';
import {AuthService} from '../auth.service';
import {MessageService} from '../message.service';
import {environment} from "../../../../environments/environment";
import {AuthRepository} from "../../repository/auth.repository";

@Injectable({providedIn: 'root'})
export class AuthenticationInterceptorService implements HttpInterceptor {

  public readonly unauthenticatedUrl: string = environment.unauthenticatedUrl();

  constructor(private messageService: MessageService,
              private authRepo: AuthRepository, private authService: AuthService) { }

  /**
   * Intercept all requests to ensure user is authenticated
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!request.url.includes('/upload')) {
          request = request.clone({
            setHeaders: {
              'Content-Type': 'application/json',
              credentials: 'include'
            },
            withCredentials: true,
          });
        } else {
          request = request.clone({
            setHeaders: {
              credentials: 'include'
            },
            withCredentials: true,
          });
        }

    return next.handle(request).pipe(
      catchError(response => this.handleError(response)));
  }

  private handleError(response: HttpErrorResponse): Observable<any> {
    return this.authService.isLoggingOut().pipe(
      take(1),
      switchMap(isLoggingOut => {
        if (response.status == 401 && !isLoggingOut && !(response.url ? response.url.indexOf(this.authRepo.impersonateUrl) >= 0 : null)) {
          this.redirect(this.unauthenticatedUrl);
          /* should never get here - only here for tests */
          return of(response);
        } else if (response.status == 401) {
          return this.showError('You must be logged in to perform this action.', response);
        } else if (response.status == 404) {
          // We don't want 404 specific error messages to show. If we want an error message to show when data doesn't
          // exist, then we should return a regular 500 error.
          return EMPTY;
        } else if (response?.error?.message) {
          return this.showError(response.error.message, response);
        } else if (response?.error?.error) {
          return this.showError(response.error.error, response);
        } else {
          return this.showError(response.message, response);
        }
      })
    );
  }

  redirect(toUrl: string) {
    return window.location.replace(toUrl);
  }

  private showError(errorMessage:string, error: HttpErrorResponse): Observable<any> {
    this.messageService.showErrorMessage(`Error: ${errorMessage}`);
    return throwError(() => error);
  }
}

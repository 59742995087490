import {inject, Injectable} from '@angular/core';
import {CanActivateFn, GuardResult, RedirectCommand, Router} from '@angular/router';
import {AuthService} from "../../core/service/auth.service";
import {filter, Observable, of, switchMap, withLatestFrom} from "rxjs";
import {take} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SupervisorGuard  {
  constructor(private router: Router,
              private authService: AuthService) {}

  canActivate(): Observable<GuardResult> {
    return this.authService.getUserId().pipe(
      filter(Boolean),
      take(1),
      withLatestFrom(this.authService.isAdmin().pipe(take(1))),
      switchMap(([, isAdmin]: [string, boolean]) => this.authService.isSupervisor().pipe(
        switchMap(isSupervisor => {
          if (!isSupervisor) {
            if (isAdmin) {
              return of(new RedirectCommand(this.router.parseUrl("/admin"), {replaceUrl: true}));
            }
            return of(new RedirectCommand(this.router.parseUrl("/"), {replaceUrl: true}));
          } else {
            return of(isSupervisor)
          }
        })
      ))
    )
  }
}

export const supervisorGuardFn: CanActivateFn = (route, state) => {
  return inject(SupervisorGuard).canActivate();
}

import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, GuardResult, RedirectCommand, Router} from '@angular/router';
import {UserService} from "../../core/service/user.service";
import {AuthService} from "../../core/service/auth.service";
import {filter, Observable, of, switchMap} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class IncomingImpersonateGuard  {
  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<GuardResult> {
    if (route.queryParamMap.has('user')) {
      return this.authService.getUserId().pipe(
        filter(Boolean),
        switchMap(() => this.authService.canImpersonate().pipe(
          switchMap(canImpersonate => {
            if (canImpersonate) {
              this.userService.impersonate(route.queryParamMap.get('user')!, "/audit");
              // This redirect won't actually do anything (the above has to handle it),
              // but we have to return something, and this is clear on what we _want_ to happen.
              return of(new RedirectCommand(this.router.parseUrl("/audit"), {replaceUrl: true}));
            } else {
              return of(new RedirectCommand(this.router.parseUrl("/"), {replaceUrl: true}));
            }
          })
        ))
      );
    } else {
      return of(new RedirectCommand(this.router.parseUrl("/"), {replaceUrl: true}));
    }
  }
}

export const incomingImpersonateGuardFn: CanActivateFn = (route, state) => {
  return inject(IncomingImpersonateGuard).canActivate(route);
}

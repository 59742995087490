import {Component} from '@angular/core';

@Component({
  selector: 'app-student-landing-page',
  templateUrl: './student-landing-page.component.html',
  styleUrls: ['./student-landing-page.component.scss']
})
export class StudentLandingPageComponent {

}
